var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"loginWrapper"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoginActive),expression:"isLoginActive"}],staticClass:"loginBox"},[_c('h3',{staticClass:"textCenter"},[_vm._v("Change Password")]),_c('div',{staticClass:"loginInner"},[_c('el-form',[_c('div',{staticClass:"gapBottom"},[_c('el-form-item',{staticClass:"input-label",class:{
                'is-error': _vm.fieldHasErrors('Current Password'),
                'is-required': _vm.isFieldRequired('Current Password'),
              },attrs:{"label":"Current Password"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"password","placeholder":"Enter your new password","show-password":"","name":"Current Password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('br'),(_vm.errors.has('Current Password'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Current Password")))]):_vm._e()],1)],1),_c('div',{staticClass:"gapBottom"},[_c('el-form-item',{staticClass:"input-label",class:{
                'is-error': _vm.fieldHasErrors('New Password'),
                'is-required': _vm.isFieldRequired('New Password'),
              },attrs:{"label":"New Password"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"New Password",attrs:{"type":"password","placeholder":"Enter your new password","show-password":"","name":"New Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('br'),(_vm.errors.has('New Password'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("New Password")))]):_vm._e()],1)],1),_c('div',{staticClass:"gapBottom"},[_c('el-form-item',{staticClass:"input-label",class:{
                'is-error': _vm.fieldHasErrors('Confirm Password'),
                'is-required': _vm.isFieldRequired('Confirm Password'),
              },attrs:{"label":"Confirm Password"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:New Password'),expression:"'required|confirmed:New Password'"}],attrs:{"type":"password","placeholder":"Re-enter your new password","show-password":"","name":"Confirm Password"},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}}),_c('br'),(_vm.errors.has('Confirm Password'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Confirm Password")))]):_vm._e()],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.ChangePassword}},[_vm._v("Reset")])],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
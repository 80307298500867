


























































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button } from "element-ui";
import commonstore from "@/store/modules/common";
import authStore from "@/store/modules/auth";
import { RootRouter } from "@/utils/routePathContsant";
import { eventHub } from "@/utils/eventHub";
import { JWTModule } from "@/store/modules";

@Component({
  components: {
    Input,
    Button,
  },
})
export default class ChangePassword extends Vue {
  oldPassword: string = "";
  password: string = "";
  rePassword: string = "";
  isLoginActive = false;
  created() {
    eventHub.$on("error", this.errorHandler);
  }
  async ChangePassword(e: any) {
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      this.isLoginActive = true;
      const message = await authStore.changePassword({
        currentPassword: this.oldPassword,
        newPassword: this.password,
        reNewPassword: this.rePassword,
      });
      this.$snotify.success(message);
      this.isLoginActive = false;
      JWTModule.clearJWT();
      this.$router.push({ name: "NewHome" });
    }
  }
  errorHandler(e: any) {
    this.isLoginActive = false;
  }
}
